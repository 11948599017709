(() => {


	const isi = () =>{
		let $windowHeight = $(window).height();
		let homeBase = $("#patient-isi").offset.top;
		$(window).scroll(function() {
			if(($(window).scrollTop() + $windowHeight) > homeBase){
				$("#sticky-isi").stop().removeClass('slideOutDown').addClass('slideOutDown');
			} else {
				$("#sticky-isi").stop().removeClass('slideOutDown').addClass('slideInUp');
			}
		});
	};

	isi();

})()