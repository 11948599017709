(() => {

	const breadCrumbMaker = (seperator) => {
		let home = $('.logo').attr('href');
		let bread = $('.mtBreadCrumb');
		let location = window.location.pathname;
		let getEach = location.split('/').slice(1);
		
		bread.append('<a href="' + home + '">Home</a>' + " " + seperator + " ");
		$.each(getEach, function(i){
			if(i + 1 === getEach.length){



				let template = this.replaceAll("-", " ");
				if(template === "compliance"){
					template = "Ethics & Compliance";
				 }
				bread.append(template);
			} else {
				let template = '<a href="/' + this + '">' + this.replace("-", " ") + '</a>' + " " + seperator + " ";
				bread.append(template);
			}
		});
	}

	breadCrumbMaker(">");

})()