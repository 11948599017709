(() => {

	const navigation = () => {
		let parent = $('.dropParent');
		let trigger = $('.dropDown');
		let url = window.location.pathname;
		let main = $('.mtNav a');

		main.each(function(){
			let $this = $(this);
			let $href = $this.attr('href');
			if($href == url){
				$this.addClass('active');
			}

		});

		parent.hover(function(){
			$(this).find(trigger).stop().slideToggle();
		}, function(){
			$(this).find(trigger).stop().hide();
		});

		$('.dropParent a').focus(function(){
			$(this).parent().find(trigger).slideToggle();
		});

		$('.hamburger').click(function() {
			$(this).stop().toggleClass('is-active');
			$('.mobileMenu').stop().slideToggle();
		});

	}

	navigation();

})()