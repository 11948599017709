(() => {

	const formDisable = () => {
		let form = $('form');

		form.on('submit', function(){
			$('.mtSubmit').prop('disabled', true);
		});
	}


	const customValidation = (selector, message) => {
		let input = document.querySelector(selector);

		if(input) {
			input.addEventListener('invalid', function (event) {
			  if (event.target.validity.valueMissing) {
				event.target.setCustomValidity(message);
				$('.requiredtext').css("color", "#C80000");
			  }
			});
			input.addEventListener('change', function (event) {
			  event.target.setCustomValidity('');
			});
		}
	}

	customValidation('input[name="firstName"]', 'Please provide your first name.');
	customValidation('input[name="lastName"]', 'Please provide your last name.');
	customValidation('input[name="email"]', 'Please provide a valid email address.');
	customValidation('input[name="company"]', 'Please provide a company name.');
	customValidation('input[name="zip"]', 'Please provide a zip code.');
	customValidation('input[name="phone"]', 'Please provide a phone number.');

	const zipCode = () => {

		$.fn.inputFilter = function(inputFilter) {
			return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
				if (inputFilter(this.value)) {
					this.oldValue = this.value;
					this.oldSelectionStart = this.selectionStart;
					this.oldSelectionEnd = this.selectionEnd;
				} else if (this.hasOwnProperty("oldValue")) {
					this.value = this.oldValue;
					this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
				} else {
				this.value = "";
				}
			});
		};

		$(".zip").inputFilter(function(value) {
			return /^\d*$/.test(value);    // Allow digits only, using a RegExp
		});
	}

	zipCode();

	const phoneFormat = () => {

		let $phone = $('.phone');
		$('.phone').keydown(function (e) {
			let key = e.which || e.charCode || e.keyCode || 0;
			let $phone = $(this);

			// Don't let them remove the starting '('
			if ($phone.val().length === 1 && (key === 8 || key === 46)) {
				$phone.val('('); 
				return false;
			} 
			// Reset if they highlight and type over first char.
			else if ($phone.val().charAt(0) !== '(') {
				$phone.val('('+$phone.val()); 
			}

			// Auto-format- do not expose the mask as the user begins to type
			if (key !== 8 && key !== 9) {
				if ($phone.val().length === 4) {
					$phone.val($phone.val() + ')');
				}
				if ($phone.val().length === 5) {
					$phone.val($phone.val() + ' ');
				}           
				if ($phone.val().length === 9) {
					$phone.val($phone.val() + '-');
				}
			}

			// Allow numeric (and tab, backspace, delete) keys only
			return (key == 8 || 
					key == 9 ||
					key == 46 ||
					(key >= 48 && key <= 57) ||
					(key >= 96 && key <= 105)); 
		})
		.bind('focus click', function () {
			$phone = $(this);

			if ($phone.val().length === 0) {
				$phone.val('(');
			}
			else {
				let val = $phone.val();
				$phone.val('').val(val); // Ensure cursor remains at the end
			}
		})
		.blur(function () {
			$phone = $(this);

			if ($phone.val() === '(') {
				$phone.val('');
			}
		});
	}

	// phoneFormat();

	$('.checkbox :checkbox').on('change', function(e) {
	$(this).closest('label').next(':text').toggle(this.checked);
	}).trigger('change');

	const submiter = () => {
		let $form = $('#signupForm');

		$form.submit(function(e){
			e.preventDefault();
			let firstName = $('#firstName').val();
			let lastName = $('#lastName').val();
			let email = $('#email').val();
			let company = $('#company').val();
			let phone = $('#phone').val();

			var data = {
				firstName : firstName,
				lastName : lastName,
				email : email,
				company : company,
				phone: phone,
				contactListId : 85066,
				contactListFolderId : 23596
			}

			$.ajax({
				url: "https://copay.medthink.com/icontact",
				type: "POST",
				data: data,
				success: function(success){
					console.log(success);
					window.location.assign('product-pipeline/thank-you')
				}
			});
		});
	}
	submiter();


})()