(() => {

	let teams = [
		{
			"name" : "Mark Hensley<br>Chief Executive Officer",
			"bio" : "<p>Mark is Chief Executive Officer of Veloxis Pharmaceuticals, Inc., an Asahi Kasei company. He joined Veloxis in 2016 as the company’s National Sales Director and transitioned into roles of Vice President, Head of Sales in 2017, Chief Commercial Officer in 2018, and Chief Operating Officer in 2020 before accepting the CEO position in October 2021. Mark also currently sits on the board of directors of Veloxis Pharmaceuticals, Inc., as well as Xenikos B.V., a clinical-stage, biopharmaceutical company based in the Netherlands.</p><p>Mark has more than 15 years' experience in a variety of pharmaceutical sales and leadership positions. Before joining Veloxis, Mark held senior sales leadership roles at Cornerstone Therapeutics, Inc. and Chiesi USA, Inc. Mark helped lead the successful transaction between Veloxis and Asahi Kasei Corporation in 2019 and built the Veloxis commercial team that launched a branded product for kidney transplant.</p><p>Mark holds a BS in Biology, with a minor in Chemistry, from the University of North Texas.</p>",
			"img" : "/images/team/Hensley.png",
			"alt" : "Headshot of Mark Hensley"
		},
		{
			"name" : "Tunde Otulana, MD<br>Chief Medical Officer",
			"bio" : "<p>Dr. Tunde Otulana joined Veloxis Pharmaceuticals as Chief Medical Officer in August 2020.</p><p>Before joining Veloxis, Dr. Otulana was Senior Vice President, Chief Medical Officer, at Mallinckrodt Pharmaceuticals from January 2016 to July 2020, where he had responsibility for global medical affairs, health economics and outcomes research, global pharmacovigilance, and medical communications.</p><p>Prior to Mallinckrodt, Dr. Otulana was Senior Vice President for Clinical Development and Medical Affairs (CDMA) at Boehringer Ingelheim Pharmaceutical from May 2013 to January 2016 and Vice President for Respiratory Therapeutic Area from December 2011 to May 2013. From 1998 to 2011, he held positions of increasing responsibility—from Vice President to Senior Vice President and Chief Medical Officer in the biotech industry in California, overseeing clinical development and operations, regulatory affairs, R&D, and toxicology. From 1991 to 1997, he served as Medical Officer and Clinical Team Leader in the Pulmonary & Allergy Drug Products Division at the FDA.</p><p>Dr. Otulana received his medical degree from the University of Ibadan, Ibadan, Nigeria. He completed his residency training in internal medicine at the University College Hospital, Ibadan, and fellowship in pulmonary medicine at Addenbrooke’s and Papworth Hospitals, University of Cambridge, UK, and at Howard University Hospital, Washington, DC.</p><p>Dr. Otulana is Clinical Professor of Pulmonary and Critical Care Medicine at the University of California, Davis Medical School as Volunteer Clinical Faculty. He also serves as Non-Executive Director on the Research, Education, and Innovation Board of Children’s National Hospital, Washington, DC.</p>",
			"img" : "/images/team/Otulana.png",
			"alt" : "Headshot of Tunde Otulana, MD"
		},
		{
			"name" : "Ulf Meier-Kriesche, MD, FAST<br>Chief Scientific Officer",
			"bio" : "<p>Dr. Meier-Kriesche joined Veloxis in September 2017 as Chief Scientific Officer.</p><p>Dr. Meier-Kriesche is a board-certified nephrologist with more than 20 years’ practical clinical experience in transplantation. He has authored over 170 scientific publications that have appeared in numerous peer-reviewed journals, including <i>The New England Journal of Medicine</i>. Additionally, Dr. Meier-Kriesche served on the faculty of the University of Florida Medical School from 2001 until 2011.</p><p>Prior to joining Veloxis, Dr. Meier-Kriesche was the Clinical Trials Lead Immunology at Bristol-Myers Squibb. Dr. Meier-Kriesche has also worked for Astellas Pharma. His industry experience covers medical affairs, drug development, and outcomes research in nephrology, gastroenterology, oncology, lupus, and dermatology.</p><p>Dr. Meier-Kriesche earned his medical degree at the University of Perugia in Italy and completed his specialty board in nephrology at the University of Cagliari. He has practiced medicine in the United States, Germany, and Italy.</p>",
			"img" : "/images/team/Meier-Kriesche.png",
			"alt" : "Headshot of Ulf Meier-Kriesche, MD, FAST"
		},
		{
			"name" : "Kamal Shah, MD, DA<br>Vice President, Pharmacovigilance",
			"bio" : "<p>Dr. Shah joined Veloxis in April 2021 as Vice President of Pharmacovigilance and Drug Safety.</p><p>Dr. Shah is a trained anesthesiologist bringing practical experience to Veloxis. Dr. Shah has worked in the pharmaceutical/biotech industry consulting for companies such as Bristol-Myers Squibb, Pfizer, and Celgene in roles varying from clinical development, medical affairs, and pharmacovigilance and drug safety, leading a team of 10-70 direct and indirect reports across the globe. Dr. Shah has been involved with multiple drugs, including biologics and combination products, with approvals around the world in the fields of oncology, cardiology, and immunology. Dr. Shah has authored more than 50 peer-reviewed publications in various US and European journals, including some on real-world disease burden and comorbidities.</p>",
			"img" : "/images/team/KamalShah.png",
			"alt" : "Headshot of Kamal Shah, MD, DA"
		},
		{
			"name" : "Ed Sleeper<br>Chief Ethics and Compliance Officer",
			"bio" : "<p>Ed is Chief Ethics and Compliance Officer of Veloxis Pharmaceuticals, Inc., an Asahi Kasei company, joining the organization in 2023.</p><p>Ed has more than 25 years of experience in the healthcare and pharmaceutical space.  Before joining Veloxis, Ed served as Chief Ethics and Compliance Officer for HutchMed International and Esperion Therapeutics where he rapidly established compliance programs to support product commercialization.  Ed also served as an executive leader in Global Compliance at Celgene supporting multiple franchises across several countries.  Prior to his move to industry, Ed was an Executive Compliance Consultant for over 10 years working closely with small and midsize organizations to develop and execute their compliance programs.</p><p>Ed holds a MS in Organizational Design and Communications from Ithaca College and a Healthcare Care Compliance Certification from Seton Hall University School of Law.</p>",
			"img" : "/images/team/Sleeper.webp",
			"alt" : "Headshot of Ed Sleeper"
		},
		{
			"name" : "Steven G. Rizk, PharmD, JD<br>Vice President, Medical Affairs",
			"bio" : "<p>Dr. Rizk joined Veloxis in August 2021 and is our Vice President of Medical Affairs.</p><p>Dr. Rizk is an accomplished biopharma medical affairs executive and a recognized industry leader in the transplant community. In his 20 years in the biopharma industry, he has worked on more than 15 products spanning all life-cycle stages, focusing on medical affairs and late-stage clinical development. Dr. Rizk’s work has resulted in numerous academic research collaborations and high-impact scientific publications to enhance the understanding and access to innovative therapeutics. In addition, he has been an advocate for including the voice of patients in clinical drug development and has led numerous initiatives to make complex scientific information understandable and accessible to all people.</p><p>Prior to joining Veloxis, Dr. Rizk has worked at Takeda, Genentech, Bristol-Myers Squibb, and Hoffmann-La Roche.</p><p>Dr. Rizk has a Bachelor’s and a Doctorate degree in Pharmacy from the Ernest Mario School of Pharmacy in New Jersey and a Juris Doctor degree from Fordham University School of Law in New York City.</p>",
			"img" : "/images/team/StevenRizk.png",
			"alt" : "Headshot of Steven G. Rizk, PharmD, JD"
		},
		// {
		// 	"name" : "Umang S. Shah, PhD, MBA, RAC, RPh<br>Vice President, Regulatory Affairs",
		// 	"bio" : "<p>Dr. Shah joined Veloxis in November 2021 as Vice President of Regulatory Affairs. He has over 25 years’ experience in the pharmaceutical industry as a scientist, research fellow, and regulatory expert in small molecules, biologics (natural proteins, recombinant proteins and peptides, viral and nonviral gene therapy, blood and related products, vaccines) and devices.</p><p>Dr. Shah has provided regulatory expertise, insight, know-how, and leadership in the areas of current and developing regulatory policies, management of regulatory research functions (Division of Biological Standards and Quality Control), and establishment of research collaborations (Health and Human Services).</p><p>At Novartis Vaccines, Dr. Shah led the global registration and approval of the first cell-based influenza vaccine (Flucelvax). Subsequently at Merck he was responsible for leading the approvals of Zinplava (bezlotoxumab, monoclonal antibody for reducing recurrence of <i>clostridium difficile</i> infection) in several regions of the world, including the US and EU, and also led the approval of biosimilar Insulin Glargine (Pen Auto-Injector and vial) in the US and EU.</p><p>Most recently at Kedrion Biopharma, Inc., Dr. Shah led programs for orphan and rare disease indications, driving activities through TPPs, input into clinical development plans, protocol designs, and assessment of study results to meet regulatory and scientific objectives.</p><p>Dr. Shah earned his PhD at the University of Maryland, his MBA at the University of Michigan, and his MS from Johns Hopkins University. He has 4 patents, over 6 publications in peer-reviewed journals, several book chapters, and contributed as instructor in eLearning programs. He is also a registered pharmacist in New Jersey, North Carolina, and Georgia.</p>",
		// 	"img" : "/images/team/UmangShah.webp",
		// 	"alt" : "Headshot of Umang S. Shah, PhD, MBA, RAC, RPh"
		// },
		{
			"name" : "Stacy Wheeler<br>Senior Vice President, Operations",
			"bio" : "<p>Stacy joined Veloxis in March 2016 as Vice President of Supply and Trade Relations. With Veloxis’s growth over the years, Stacy’s leadership has continued to expand. Today, as Senior Vice President of Operations, Stacy leads a robust team consisting of Manufacturing, Quality, and Supply Chain.</p><p>Stacy has more than 20 years’ experience managing global pharmaceutical supply chains at Biovail, Cornerstone Therapeutics, and Chiesi USA. At Chiesi USA, she was responsible for a wide variety of products with complex, international supply chains spanning a variety of licensing, purchase, and manufacturing agreements.</p><p>Stacy holds a BS from North Carolina State University.</p>",
			"img" : "/images/team/Wheeler.png",
			"alt" : "Headshot of Stacy Wheeler"
		},
		{
			"name" : "Marcelo Anderson<br>Vice President, Manufacturing and Technical Operations",
			"bio" : "<p>Marcelo joined Veloxis in 2022, bringing more than 27 years of technical and leadership experience in the biopharmaceutical industry.</p><p>With over 27 years in biopharmaceuticals, Marcelo is a seasoned executive with a track record of excellence in operations and leadership. At Veloxis Pharmaceuticals, he builds on a legacy that includes 12 transformative years at Biogen, where he last served as the Global Head of Strategic Partnerships, cultivating pivotal industry alliances.</p><p>Marcelo’s strategic foresight was honed through his role in establishing the Biomanufacturing Training and Education Center (BTEC) at NC State University, now the largest of its kind globally. His industry expertise was further developed over a decade at Amgen in various operational positions.</p><p>Academically, Marcelo is equally accomplished, with an MS in Business Administration from IAE University, France; a Masters in Global Innovation Management; and an MBA from NC State University. His blend of operational acumen and educational prowess positions him as a vanguard in biopharmaceutical innovation.</p>",
			"img" : "/images/team/Marcelo-Anderson.webp",
			"alt" : "Headshot of Marcelo Anderson"
		},
		{
			"name" : "Noel Barnard<br>Vice President, Legal and General Counsel",
			"bio" : "<p>Noel joined Veloxis in March 2016 as Senior Director, Legal Affairs, and was promoted to Vice President, Legal and General Counsel, in December 2018. Noel is responsible for overseeing all aspects of Veloxis’s global legal affairs.</p><p>Previously, Noel was a part of the legal team at Chiesi USA, Inc. In that capacity, Noel held broad responsibilities that included overseeing of the company’s intellectual property portfolio and commercial contracting function. Noel also served as Assistant General Counsel at Cornerstone Therapeutics Inc., prior to its acquisition by Chiesi Farmaceutici S.p.A.</p><p>Noel holds a BS in Biomedical Engineering from the University of Tennessee and a Juris Doctor from the University of North Carolina School of Law.</p>",
			"img" : "/images/team/Barnard.png",
			"alt" : "Headshot of Noel Barnard"
		},
		{
			"name" : "Eric Hedge<br>Vice President, Sales",
			"bio" : "<p>Eric joined Veloxis in July 2023 as our Vice President of Sales. Eric brings more than 25 years of pharmaceutical leadership experience in diverse organizational structures, from startups to market leadership in the oncology, hematology, immunology, bone marrow transplant, solid organ transplant, and rare disease markets. Before joining Veloxis, Eric served as the Vice President of Sales for Kadmon, a Sanofi company, where he was responsible for both the Bone Marrow Transplant and Solid Organ Transplant teams. Eric also held senior sales leadership roles at Spectrum Pharmaceuticals, Inc. and at Dendreon Corporation.</p><p>Eric holds a BS from California State University, Fresno.</p>",
			"img" : "/images/team/Eric-Hedge.webp",
			"alt" : "Headshot of Eric Hedge"
		},
		{
			"name" : "Libbie McKenzie, MD FASN<br>Vice President, Clinical Development and Operations",
			"bio" : "<p>Libbie is Vice President and Head of Clinical Development and Operations at Veloxis Pharmaceuticals, Inc., an Asahi Kasei company, joining the organization in 2023.</p><p>Libbie is a Fellow in the American Society of Nephrology and maintained a clinical practice for 15 years, having been board certified in Internal Medicine and Nephrology. She also has extensive bench and translational research experience in cell biology, immunology, and oncology. She has been a physician leader in the pharmaceutical and biotechnology industries for over 18 years. Libbie helped develop and grow the medical monitoring, safety, regulatory, and medical affairs functions at IQVIA (Quintiles) during a 15-year term that saw her achieve  increasing levels of accountability, culminating as Vice President of the Pharmacovigilance and Medical Information organization. She has supported clinical development across all major therapeutic areas and was directly responsible for creating IQVIA’s medical data review team, the safety surveillance and analytics functions, the global medical information and local regulatory services, and the integrated compliance offering. Prior to joining Veloxis, Libbie was Chief Medical Officer at a clinical stage cell therapy biotech targeting chronic kidney disease.</p><p>Libbie is a summa cum laude graduate of Duke University and obtained her MD at Duke’s School of Medicine. She completed both an Internal Medicine residency and Nephrology fellowship at the Duke University Medical Center. She will complete her MBA at Wake Forest University in 2024.</p>",
			"img" : "/images/team/Libbie-McKenzie.webp",
			"alt" : "Headshot of Libbie McKenzie"
		},
		{
			"name" : "Tracy Sapp<br>Vice President, Quality",
			"bio" : "<p>Tracy joined Veloxis Pharmaceuticals, Inc., an Asahi Kasei company, in 2023 as Vice President of Quality.</p><p>Tracy has over 15 years of professional experience in biotechnology and pharmaceutical operations. Before joining Veloxis, Tracy served as the Head of Quality Management Systems and Compliance for the advanced therapies and large molecule divisions at Novartis, focusing on quality system integration and remediation. At Thermo Fisher Scientific, Tracy was responsible for North America Quality and Compliance for the drug product division. Her experience also spans manufacturing, automation engineering, and quality, where she held various roles during her tenure at Biogen.</p><p>Tracy holds a BS in Chemical Engineering from NC State University and an MS in Regulatory Science from the University of Maryland, School of Pharmacy.</p>",
			"img" : "/images/team/Tracy-Snapp.webp",
			"alt" : "Headshot of Tracy Sapp"
		},
		{
			"name" : "Daisuke Okajima<br>Vice President, Global Strategy & Planning",
			"bio" : "<p>Daisuke joined Asahi Kasei in 1999 and has served as a liaison between Veloxis and Asahi Kasei since April 2020. In August 2020, he relocated to Cary, North Carolina, and joined Veloxis.</p><p>Daisuke has more than 20 years' experience in a variety of pharmaceutical positions, including sales, marketing, strategy planning, launch planning, and clinical development. Prior to joining Veloxis, Daisuke was the General Manager, Pharmaceutical Sales Planning & Control Department of Asahi Kasei Pharma.</p><p>Daisuke holds an MBA from the University of Edinburgh Business School (class of 2010-2011) and a BS (majored in Molecular Biology) from Waseda University in Japan.</p>",
			"img" : "/images/team/DaisukeOkajima.png",
			"alt" : "Headshot of Daisuke Okajima"
		}

	]

	const modalMaker = (template) => {
		let screen = '<div class="mtScreen"></div>';
		$('body').append(screen);
		$('body').append(template);
		$('body').css('overflow', 'hidden'); 
		$("body").addClass("modal-open");
	}
	const modalRemover = () => {
		$('.mtModal').remove();
		$('.mtScreen').remove();
		$('body').css('overflow', 'auto');
		$("body").removeClass("modal-open")
	}

	const cookieWarning = () => {
		let check = Cookies.get('warning');
		let optOut = sessionStorage.getItem('optOut');
		// pass cookie warning template
		let template = '<div class="mtModal ex-link"> <div class="modal-content"> <div class="grid-container"> <div class="grid-x grid-margin-x grid-padding-y text-center" style=""> <div class="cell auto" style=""> <h2>Privacy &amp; Cookies Policy</h2> <p>This website uses cookies to improve your experience. We’ll assume it’s okay to use your cookies in accordance with our <a href="/privacy-policy" target="_blank">Privacy Policy</a>, but you can opt out if you wish.</p></div></div><div class="grid-x grid-padding-y"> <div class="cell small-12 large-auto text-right btn-center-mob" style="padding-top:0;"><a href="#" class="mtDecline btn-blue" style="margin-top:0;">Opt out</a></div><div class="cell small-12" style="width:30px;"></div><div class="cell small-12 large-auto text-left btn-center-mob" style="padding-top:0;"><a href="#" class="mtAccept btn-blue" style="margin-top:0;">Accept all</a></div></div></div></div></div>';

		

		if(check == "1" || optOut == "1") {

		} else {
			modalMaker(template);
		}

		$('.mtAccept').click(function(e){
			e.preventDefault();
			modalRemover();
			Cookies.set('warning', '1');
		});
		$('.mtDecline').click(function(e){
			e.preventDefault();
			modalRemover();
			sessionStorage.setItem('optOut', '1');
		});
	}

	cookieWarning();

	const leaving = () => {
		$('a').click(function(e){
			if($(this).data('modal')){
				e.preventDefault();
				let dest = $(this).data('modal');
				// pass leaving intersitial template
				let template = '<div class="mtModal ex-link"><div class="modal-content no-pad-top"><div class="grid-container"><div class="grid-x grid-margin-x grid-padding-y" style=""><div class="cell auto text-right" style="padding-bottom:0;"><div class="modal-close"><a href="#" class="mtClose" style="color:#333;font-size:50px;text-decoration:none!important;">×</a></div></div></div><div class="grid-x grid-margin-x grid-padding-y text-center" style=""><div class="cell small-12 large-auto"><h2>Ready to leave Veloxis.com?</h2><br><a href="#" class="btn-blue mtClose">Go Back</a><a href="' + dest + '" class="btn-blue rem-marg-mob" target="_blank" style="margin-left:30px;">Continue</a></div></div></div></div></div>';
				modalMaker(template);
				$('.mtClose').click(function(e){
					e.preventDefault();
					modalRemover();
				});
				$('.mtScreen').click(function(e){
					e.preventDefault();
					modalRemover();
				});
			}
		});
	}

	leaving();

	const team = () => {
		$('a').click(function(e){
			if($(this).data('team')){
				e.preventDefault();
				let member = $(this).data('team');
				let person = $.grep(teams, function(obj){return obj["name"] === member;})[0];
				let template = '<div class="mtModal modal-team"><div class="modal-content no-pad-top"><div class="grid-container"><div class="grid-x grid-margin-x grid-padding-y" style=""><div class="cell auto text-right" style="padding-bottom:0;"><div class="modal-close"><a href="#" class="mtClose" style="color:#333;font-size:50px;text-decoration:none!important;">×</a></div></div></div><div class="grid-x grid-margin-x grid-padding-y" style=""><div class="cell small-12 large-auto"><img src="'+ person.img +'" alt="'+ person.alt +'"/></div><div class="cell small-12 large-auto"><h3>'+ person.name +'</h3><p>'+ person.bio +'</p></div></div></div></div></div>';
				modalMaker(template);
				$('.mtClose').click(function(e){
					e.preventDefault();
					modalRemover();
				});
				$('.mtScreen').click(function(e){
					e.preventDefault();
					modalRemover();
				});
			}
		});

	}

	team();


})()