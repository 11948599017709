$(document).ready(function(){
	$(document).foundation();
	$('.match').matchHeight();
	$('.match-2').matchHeight();
	$('#submit-medinfo').click(verifyCheckbox);
});


var verifyCheckbox = function () {
	var checkboxes = $('.cred-check-wrapper');
	var inputs = checkboxes.find('input');
	var first = inputs.first()[0];

	inputs.on('change', function () {
		this.setCustomValidity('');
	});


	first.setCustomValidity(checkboxes.find('input:checked').length === 0 ? 'Please select at least one option to proceed' : '');
}

function validateRecaptcha() {
	var response = grecaptcha.getResponse();
	if (response.length === 0) {
		$('#g-recaptcha').append('Please verify you are not a robot.')
		return false;
	} else {
		return true;
	}
}