(() => {

	const referencer = () => {
		let count = 0;
		$('.references li').each(function(){
			count++;
			var each = $(this).data('refs');
			var template = '<strong>' + count + '.</strong> ' + each + " ";
			$('.refBlock').append(template);
		});
		if(count > 1){
			$('.refBlock').prepend('<strong style="display:block;">References</strong>');
		} else {
			$('.refBlock').prepend('<strong style="display:block;">Reference</strong>');
		}
	}

	referencer();

})()