(() => {

	const imageDescriptions = () => {
		let target = $('.mtIDT');
		let desc = $('.mtID');
		target.click(function(e){
			e.preventDefault();
			var span = $(this).find('span');
			$(this).parent().find(desc).stop().slideToggle();
			$(this).find('span').text((span.text() == 'Close') ? 'Expand' : 'Close');
			$(this).find('i').toggleClass('fa-plus fa-minus');
		});
	}

	imageDescriptions();

	// const faq = () => {
	// 	let $q = $('.question');
	// 	let $a = $('.answer');
	// 	$q.click(function(e){
	// 		e.preventDefault();
	// 		if($a.is(':visible')){
	// 			$a.stop().slideUp();
	// 		}
	// 		$(this).parent().find($a).slideToggle();
	// 	});
	// }

	// faq();

	const faq = () => {
		let $q = $('.question');
		let $a = $('.answer');
		$a.hide();
		$q.click(function(e){
			e.preventDefault();
			// if($a.is(':visible')){
	 	// 		$a.stop().slideUp();
	 	// 	}
			$(this).parent().find($a).stop().slideToggle();
			$(this).find('i').toggleClass('fa-plus fa-minus')

		});
		$q.focusin(function(e){
			e.preventDefault();	
			$(this).parent().find($a).slideDown();
			$(this).parent().find('i').toggleClass('fa-plus fa-minus')

		});
	}

	faq();


	const toggleAll = () => {
		let trigger = $('.faqAll');
		let $a = $('.answer');
		trigger.click(function(e){
			e.preventDefault();
			$(this).stop().toggleClass("expand");
			if(trigger.hasClass("expand")){
				$a.stop().slideDown();
				$(this).html('Collapse All');
			} else {
				$a.stop().slideUp();
				$(this).html('Expand All');
			}
		});
	}

	toggleAll();

})()